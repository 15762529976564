@import "variables";
/*Homepage Header Styles*/
/*1080*/

.Homepage-Header-logo {
  position: fixed;
  width: 153.355px;
  height: 52.109px;
  flex-shrink: 0;
  left: 47px;
  top: 76px;
  cursor: pointer;

  @media (max-width: 992px) {
    display: none;
  }

  &:hover {
    opacity: 70%;
  }
}
.mobile-logo {
  @media (min-width: 993px) {
    display: none;
  }
}

header.homepage {
  z-index: 2;
  position: fixed;
  width: 100%;
  top: 0px;
  background: #fafafa;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  flex-flow: column;
  @media (max-width: 992px) {
    justify-content: initial;
    flex-flow: row;
    width: 100%;
  }
}

.promo-banner {
  padding: 16px;
  background: $color-black-emphasis;
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: 992px) {
    display: none;
  }
}
.mobile-nav {
  display: flex;
  gap: 16px;
  @media (min-width: 993px) {
    display: none;
  }
 
}

.MuiDrawer-paper {
  width: 330px;
}

.btn {
  cursor: pointer;

  color: #ffffff;
  padding: 15px 24px;
  height: inherit;
  font-family: $secondary-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  background-color: $color-primary-accent;
  border-radius: 100px;
  border: 0;

  &:hover {
  }

  &.outline-btn {
    border: 1px solid $color-black-interactive;
    background-color: initial;
    color: $color-black-interactive;
  }

  &.link-btn {
    color: #fff;
    text-align: center;
    font-family: $main-font;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    background: initial;
    border: initial;
    padding: 0;
    text-transform: none;
    &:hover {
      opacity: 0.7;
    }
  }
  &.icon-btn {
    display: flex;
    gap: 12px;

    &.icon-arrow-forward {
      &:after {
        content: "";
        background-image: url("#{$assets_url}img/icons/arrow_forward.svg");
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
}
.link-grp {
  display: flex;
  align-items: center;
}

.header-inner {
  position: relative;
  height: 100px;
  width: 90%;
  @media (max-width: 992px) {
    display: flex;
    height: initial;
    padding: 12px 15px;
    justify-content: space-between;
    width: 100%;
  }
}

.nav-big {
  padding-left: 8em;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  padding-right: 15em;
  display: flex;
  justify-content: end;
  gap: 8px;
  @media (max-width: 1200px) {
    padding-right: 47px;
  }
  @media (max-width: 992px) {
    display: none;
  }
}

.nav-big-link {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  display: flex;
  padding: 20px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &.active-item {
    color: $color-primary-accent;
  }

  cursor: pointer;
  color: rgb(0, 0, 0);
  text-decoration: none !important;

  &:hover {
    opacity: 70%;
  }
}

.button-grp {
  display: flex;
  align-items: center;
  gap: 16px;
}

.JoinUs-Button {
  &:hover {
    opacity: 70%;
  }
}

.Login-Button {
}

@media (min-width: 1400px) {
  .button-grp {
    right: 10em;
  }
}

@media (min-width: 1600px) {
  .button-grp {
    right: 15em;
  }
}

@media (max-width: 900px) {
  .button-grp {
    display: grid;
    top: 5px;
  }

  .JoinUs-Button {
    width: 158px;
    height: 44px;
  }

  .Login-Button {
    margin-left: 0px;
    margin-top: 5px;
    width: 158px;
    height: 44px;
  }
}

@media (max-width: 680px) {
  .link-grp {
    display: grid;
    top: 0em;
  }

  .nav-big-link {
    padding-top: 10px;
  }

  .button-grp {
    display: grid;
  }

  .nav-big-link {
    text-align: center;
  }
}

@media (max-width: 511px) {
  .link-grp {
    left: 4em;
  }

  .Homepage-Header-logo {
    left: 12px;
  }

  .nav-big-link {
    text-align: center;
    font-size: 22px;
    line-height: 35px;
  }

  .JoinUs-Button,
  .Login-Button {
    width: 90px;
  }
}
