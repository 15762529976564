/*Footer*/
/*1080*/

.Homepage-Footer {
    margin-top: 1px;
    background: #040707;
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.Homepage-Footer-Logo {
    position: absolute;
    width: 100px;
    left: 70px;
    top: 77px;
    cursor: pointer;
    @media screen and (max-width: 992px) {
        position: relative;
        left: auto;
        top: auto;
    }
}

ul.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.footer-circle-grey {
    position: absolute;
    width: 728px;
    height: 728px;
    bottom: -260px;
    left: -459.77px;
    border-radius: 50%;
    background: rgba(65, 65, 65, 0.95);
    @media screen and (max-width: 992px) {
        left: -301px;
        top: -484px;
        bottom: initial;
    }
}
.footer-elements {
    @media screen and (max-width: 992px) {
        position: relative;
    }
}
.footer-body {
    @media screen and (max-width: 992px) {
        padding-top: 56px;
    }
}

.footer-circle-red {
    position: absolute;
    width: 398.03px;
    height: 398.03px;
    right: -4em;
    bottom: -225px;
    border-radius: 50%;
    background: #410002;
    @media screen and (max-width: 992px) {
        right: -152.02px;
        bottom: -240.32px;
    }
}
.footer-cta {
    padding-bottom: 36px;
}
.footer-copy-text {
    position: absolute;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #8890a4;

    right: 0;
    top: 0;
    padding: 16px 24px;

    @media screen and (max-width: 992px) {
        position: initial;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.7);
        padding-top: 0;
    }
}

.sub-footer {
    border-top: 1px solid rgba(136, 144, 164, 0.43);
    position: relative;
    .sub-footer-links {
        ul {
            padding-bottom: 10px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: rgba(65, 65, 65, 0.95);
            padding: 0;
            margin: 0;
            
            @media screen and (max-width: 992px) {
                padding: 0;
                margin: 0;
                color: rgba(255, 255, 255, 0.7);
                display: flex;
                justify-content: space-between;
                padding: 0 24px;
                max-width: 400px;
                margin: auto;
            }

            > li {
                display: inline-block;
                padding: 16px 20px;
                @media screen and (max-width: 992px) {
                    padding: 16px 0;
                }

                &:hover {
                    opacity: 70%;
                    cursor: pointer;
                }
            }
        }
    }
}

div.right-footer-menu {
    padding-top: 4em;
    @media screen and (max-width: 992px) {
        padding-top: 56px;
    }
}

ul {
    &.right-footer-menu {
        display: inline-flex;
        justify-content: space-between;
        @media screen and (max-width: 992px) {
            gap: 24px;
        }
        > li {
            padding: 10px;
        }
    }

    &.left-footer-menu {
        padding: 10px 20px;
        position: absolute;
        left: 20em;
        bottom: 5em;
        justify-content: space-between;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: white;

        > li {
            &:hover {
                opacity: 70%;
                cursor: pointer;
            }

            padding: 10px;
        }
    }
}

h4.footer-menu {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: white;
    @media screen and (max-width: 992px) {
        margin-top: 0;
    }
}

label.subscribe-form,
h4.contact-us {
    color: white;
}

div.contact-us {
    position: absolute;
    right: 20em;
    bottom: 110px;
}

img {
    &.footer-social {
        cursor: pointer;
        border-radius: 15%;
        width: 2em;

        &:hover {
            opacity: 70%;
        }
    }

    &.twitter-padding {
        margin-top: 2px;
    }

    &.mail-padding {
        margin-top: 5px;
    }
}
