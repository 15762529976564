/* General Styles */
div.infoToInsights {
  display: flex;
  justify-content: space-between;
  margin-top: 3em;
  padding-top: 6em;
  background: #fafafa;
  overflow: hidden;
  padding-bottom: 4em;
}

.infoToInsights-img,
div.infoToInsights-content {
  width: 45%;
  position: relative;
  left: 1%;
}

div.infoToInsights-content {
  font-family: "Inter";
}

div.infoToInsights-content>h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 46px;
  color: #212b36;
}

div.infoToInsights-content>p,li {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #637381;
  text-align: left;
  padding-bottom: 0.5em;
  padding-right: 3em;
}

button.infoToInsights-content {
  width: 180px;
  height: 54px;
  background: #ed1f24;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

button.infoToInsights-content:hover {
  opacity: 70%;
}

img.imgborder {
  max-width: 100%;
  border-radius: 20px;
}

/* Responsive Styles */
@media (max-width: 1080px) {
  div.infoToInsights {
    height: 37em;
  }
}

/* Responsive Styles */
@media (max-width: 800px) {
  div.infoToInsights {
    height: initial;
    padding-top: 0;
    margin-top: 68px;
  }
}

@media (max-width: 800px) {
  div.infoToInsights {
    flex-direction: column;
    /* Stack image and text vertically on smaller screens */
  }

  div.infoToInsights-content {
    width: initial;
    padding: 0 32px;
    margin: 0 auto;
    /* Center the text content */
  }
  .infoToInsights-img {
    width: initial;
    left: initial;
  }
  .infoToInsights-img .imgborder {
    border-radius: 0;
    box-shadow: none;
  }
}

.caption {
  color: #b3b3b3;
}