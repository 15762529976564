.mailchimp-form {
    background: rgba(0, 0, 0, 0.04);
    padding: 0 24px;
    padding-bottom: 120px;
    @media screen and (max-width: 768px) {
        padding-bottom: 56px;
        padding-top: 56px;
        background: white;
    }
    #mc_embed_signup {
        max-width: 690px;
        padding: 56px 56px 48px 56px;
        margin: auto;
        background: rgba(0, 0, 0, 0.87);
        color: white;
        border-radius: 20px;
        @media screen and (max-width: 768px) {
            padding: 56px 24px 48px 24px;
        }
        h2 {
            text-align: center;
            font-family: Inter;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 55px;
            margin: 0;
            margin-bottom: 35px;
            @media screen and (max-width: 768px) {
                font-size: 28px;
                line-height: 150%;
            }
        }
        form {
            display: flex;
            flex-flow: column;
            max-width: 420px;
            margin: auto;
            .MuiButtonBase-root {
                margin-top: 32px;
                margin-bottom: 8px;
            }

            .indicates-required {
                color: #ffffffb2;
                text-align: left;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0.25px;
                .asterisk {
                    color: #ffffffb2;
                }
            }

            input {
                border: initial;
                border-radius: initial;
                &.mce_inline_error {
                    border: initial !important;
                }
            }
        }
        div#mce-responses {
            width: 100%;
            padding: 0;
            margin: 0;
        }
        div.response {
            width: 100%;
        }
    }
}
