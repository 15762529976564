/*Hero Section*/
@import "variables";

.hero-section {
  position: relative;
  width: 100%;
  overflow: hidden;

  background-image: url("#{$assets_url}/img/aipBackgroundImg.jpg");
  background-size: cover;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 0;
    background: #fafafa;
  }
}

div.hero-content {
  overflow: hidden;
  max-width: 1200px;
  display: flex;
  margin: 0 30px;

  @media screen and (max-width: 768px) {
    margin: 0;
    flex-flow: column;
  }

  .featured-section {
    background: rgba(15, 19, 19, 0.95);
    border-radius: 20px 20px 0px 20px;
    width: 50%;
    color: white;
    padding: 56px;
    padding-bottom: 80px;
    @media screen and (max-width: 1024px) and (min-width: 921px) {
      padding: 45px;
    }
    @media screen and (max-width: 920px) and (min-width: 769px) {
      padding: 30px;
    }
    @media screen and (max-width: 768px) {
      border-radius: 0;
      width: initial;
      padding: 48px 32px;
      padding-bottom: 96px;
      background: rgba(0, 0, 0, 0.87);
      &:before {
        content: "";
        background: #ed2024;
        position: absolute;
        right: -349px;
        top: -13px;
        width: 362px;
        height: 362px;
        border-radius: 362px;
      }
      &:after {
        content: "";
        background-image: url("#{$assets_url}/img/triangle.svg");
        position: absolute;
        left: -50px;
        top: 164px;
        width: 71.535px;
        height: 83.951px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    h2 {
      font-family: Inter;
      font-size: 80px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -1.5px;
      margin: 0;
      text-align: left;
      margin-bottom: 32px;
      @media screen and (max-width: 1024px) {
        font-size: 60px;
      }
      @media screen and (max-width: 920px) and (min-width: 769px) {
        font-size: 50px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 56px;
        margin-bottom: 40px;
      }
    }

    p {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      @media screen and (max-width: 768px) {
        font-size: 18px;
        margin: 0;
      }
    }
  }
  .form-section {
    border-radius: 0px 20px 20px 0px;
    background: rgba(255, 255, 255, 0.95);
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    width: 50%;
    padding: 56px 56px 80px 56px;
    flex-flow: column;
    @media screen and (max-width: 1024px) {
      padding: 40px;
    }
    @media screen and (max-width: 920px) and (min-width: 769px) {
      padding: 30px;
    }
    @media screen and (max-width: 768px) {
      margin: 0 24px;
      width: initial;
      border-radius: 4px;
      box-shadow:
        0px 9px 12px 0px rgba(0, 0, 0, 0.14),
        0px 3px 16px 0px rgba(0, 0, 0, 0.12),
        0px 5px 6px 0px rgba(0, 0, 0, 0.2);
      margin-top: -50px;
      background: white;
      padding: 24px;
      padding-bottom: 32px;
      margin-bottom: 56px;
    }

    h2 {
      text-align: center;
      font-family: Inter;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 55px;
      margin: 0;
      @media screen and (max-width: 920px) and (min-width: 769px) {
        font-size: 29px;
      }
      @media screen and (max-width: 768px) {
        font-size: 36px;
        margin-bottom: 32px;
      }
    }

    form {
      display: flex;
      flex-flow: column;
      gap: 16px;
      padding: 35px 0;
      margin: 0 auto;
      width: 100%;
      max-width: 342px;
      @media screen and (max-width: 920px) and (min-width: 769px) {
        padding: 20px 0;
      }
      > span {
        color: #00000099;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .disclaimer {
      color: var(--Black-Medium-Emphasis, rgba(0, 0, 0, 0.6));
      text-align: center;

      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      letter-spacing: 0.25px;
    }
  }
}

@media (max-width: 821px) {
  div.hero-content {
    > {
      h2 {
        font-size: 33px;
      }

      p {
        font-size: 17px;
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }
}

@media (max-width: 511px) {
  div.hero-content > {
    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
}
