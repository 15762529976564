/*Call to Action Styles*/
/*1080*/

.italic {
    font-style: italic !important;
}

div.meetAnna {
    padding-top: 6em;
    height: 760px;
    background: #FAFAFA;
    position: relative;
    overflow: hidden;
}

@media (max-width: 1400px) {
    div.meetAnna {
        padding-top: 6em;
        height: 887px;
        background: #FAFAFA;
        position: relative;
        overflow: hidden;
    }
}

@media (max-width: 1080px) {
    div.meetAnna {
        padding-top: 6em;
        height: 80%;
        background: #FAFAFA;
        position: relative;
        overflow: hidden;
    }
}

@media (max-width: 831px) {
    div.meetAnna {
        padding-top: 6em;
        height: 80%;
        background: #FAFAFA;
        position: relative;
        overflow: hidden;
    }
}


div.meetAnna-content {
    width: 50%;
    padding-left: 3em;
}



div.meetAnna-content>h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 22px;
    color: #212B36;
    text-align: left;
}

div.meetAnna-content>p {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #637381;
    text-align: left;
    padding-bottom: 1.5em
}

button.meetAnna-content {
    width: 180px;
    height: 54px;
    background: #ED1F24;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #FFFFFF;

}

div.meetAnna-content>button:hover {
    opacity: 70%;
}
@media (max-width: 831px) {

    div.meetAnna-content {
        width: initial;
        padding: 0 32px;
    }

    img.meetAnna {
        display: none;
    }

    div.meetAnna-content>h2 {
        line-height: 1;
    }
}
@media (min-width: 831px) {
    img.meetAnna {
        height: 23em;
        position: absolute;
        right: -13em;
        top: 14em;
        border-radius: 2%;
    }
}

@Media (min-width: 1200px) {
    img.meetAnna {
        height: 27em;
        position: absolute;
        right: -7em;
        top: 14em;
        border-radius: 2%;
    }
}

@media (min-width: 1400px) {
    img.meetAnna {
        height: 30em;
        position: absolute;
        right: -4em;
        top: 14em;
        border-radius: 2%;
    }
}

@media (min-width: 1600px) {
    img.meetAnna {
        height: 30em;
        position: absolute;
        right: -2em;
        top: 14em;
        border-radius: 2%;
    }
}