@import "variables";

.before-leaving-popup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.54);
    z-index: 100;
    padding: 0 24px;
    
    .before-leaving-container {
        width: 100%;
        max-width: 800px;
        @media (max-width: 768px) {
            max-width: 400px;
        }
        .bl-popup {
            border-radius: 20px;
            background: var(--White-High-Emphasis, #fff);
            display: flex;
            overflow: hidden;
            box-shadow:
                0px 19px 38px 0px rgba(0, 0, 0, 0.3),
                0px 15px 12px 0px rgba(0, 0, 0, 0.22);

            @media (max-width: 768px) {
                flex-flow: column;
            }
            .image-container {
                width: 50%;
                background: gray;
                flex: 0 0 50%;
                background-image: url("#{$assets_url}/img/before-you-go.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                @media (max-width: 768px) {
                    width: initial;
                    flex: 0 0 224px;
                }
            }
            .content-container {
                text-align: left;
                padding: 40px;
                position: relative;
                @media (max-width: 768px) {
                    padding: 40px 24px;
                    padding-bottom: 24px;
                }
                h3 {
                    font-family: Inter;
                    font-size: 34px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 51px;
                    letter-spacing: 0.25px;
                    margin: 0;
                    margin-bottom: 16px;
                }
                p {
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    margin: 0;
                }
                form {
                    padding: 0px 32px;
                    display: flex;
                    flex-flow: column;
                    margin-top: 40px;
                    margin-bottom: 16px;
                    @media (max-width: 768px) {
                        padding: 0;
                        margin-top: 24px;
                    }
                    .MuiTextField-root {
                        margin-bottom: 16px;
                    }
                }
                .close-popup {
                    padding: 10px 12px;
                    background: initial;
                    border: initial;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    text-transform: uppercase;
                    text-align: center;
                    color: var(--Primary-500---Accent, #47246b);
                    text-align: center;
                    width: 100%;

                    cursor: pointer;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
