section.pricing-plans {
    padding: 0 24px;
    padding-bottom: 120px;
    @media (max-width: 768px) {
        padding-bottom: 56px;
        position: relative;
    }
    .section-container {
        max-width: 1156px;
        margin: auto;
    }
    .plans-container {
        display: flex;
        gap: 32px;
        margin-top: -34px;
        @media (max-width: 992px) {
            flex-flow: column;
            align-items: center;
            gap: 88px;
        }
        @media (max-width: 768px) {
            margin-top: -12px;
        }

        .plan-card {
            width: 100%;
            border-radius: 20px;
            border: 2px solid var(--Gray-Divider, #e5e5e5);
            background: white;
            @media (max-width: 992px) {
                max-width: 400px;
            }
            .icon-section {
                width: 114px;
                height: 114px;
                background: #ed1f24;
                border-radius: 100%;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -57px;
                box-shadow:
                    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 18px 0px rgba(0, 0, 0, 0.12),
                    0px 3px 5px 0px rgba(0, 0, 0, 0.2);
                margin-bottom: 15px;
            }
            .plan-name {
                border-bottom: 2px solid var(--Gray-Divider, #e5e5e5);
                padding-bottom: 24px;
                min-height: 144px;
                @media (max-width: 768px) {
                    min-height: initial;
                }
                h3 {
                    font-family: Inter;
                    font-size: 34px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 51px;
                    letter-spacing: 0.25px;
                    margin: 0;
                }
                span {
                    display: block;
                    &.price {
                        text-align: center;
                        font-family: Roboto;
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 72px;
                    }
                    &.concurrency {
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0.1px;
                        color: rgba(0, 0, 0, 0.6);
                        text-align: center;
                    }
                }
            }
            .plan-description {
                padding: 24px 32px;
                border-bottom: 2px solid var(--Gray-Divider, #e5e5e5);
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-align: left;
                min-height: 96px;
                @media (max-width: 1200px) {
                    min-height: 119px;
                }
                @media (max-width: 768px) {
                    min-height: initial;
                }
                ul {
                    padding-left: 28px;
                    margin: 0;
                }
                p {
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.5px;
                    margin: 0;
                }
            }
            .plan-cta {
                padding: 24px 0;
            }
        }
    }
    .what-is {
        max-width: 720px;
        margin: auto;
        margin-top: 80px;
        @media (max-width: 768px) {
            margin-top: 56px;
        }
        h2 {
            color: #212b36;
            text-align: center;
            font-family: Inter;
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: 46px;
            margin: 0;
            margin-bottom: 24px;
        }
        p {
            color: #637381;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            text-align: justify;
        }
    }
}
.page-hero-section.plans-hero.center {
    padding: 169px 0;
    padding-top: 76px;
    @media (max-width: 768px) {
        padding: 96px 0;
        padding-top: 48px;
    }
    .heading-container {
        h1 {
            margin: 0;
            text-align: center;
            font-family: Inter;
            font-size: 60px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 75px */
            letter-spacing: -0.5px;
            @media (max-width: 768px) {
                font-weight: 400;
            }
        }
    }
}
