.be-featured {
    position: relative;
    padding-top: 2em;
    width: 100%;
    height: 370px;
    background: #FAFAFA;
    overflow: hidden;
}

.be-featured-circle {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: #ED1F24;
    left: -14em;
    bottom: 2em;
}


.be-featured-content {}

h2.be-featured-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 46px;
    color: #212B36;
}

@media (max-width: 1000px) {
    p.be-featured-content {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #637381;
        text-align: left;
        padding-left: 10em;
        padding-right: 10em;
    }
}

@media (min-width: 1000px) {
    p.be-featured-content {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #637381;
        text-align: left;
        padding-left: 20em;
        padding-right: 20em;
    }
}


@media (min-width: 1400px) {
    p.be-featured-content {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #637381;
        text-align: left;
        padding-left: 25em;
        padding-right: 25em;
    }
}

button.be-featured-content {
    width: 222px;
    height: 54px;
    background: #ED1F24;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    position: relative;
    margin-top: 2em;
}

button.be-featured-content:hover {
    opacity: 70%;
}

@media (max-width: 768px) {
    p.be-featured-content {
        padding: 0 32px;
    }
}