.request-access {
  position: relative;
  width: 100%;
  background: rgba(0, 0, 0, 0.04);
  overflow: hidden;
  padding: 120px 0;
  @media screen and (max-width: 768px) {
    background: white;
    padding: 56px 24px;
    width: initial;
  }
}

.request-access-circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #ed1f24;
  left: -14em;
  bottom: 2em;
}

@media (min-width: 1080px) {
  .request-access-triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 55px solid transparent;
    border-right: 55px solid transparent;
    border-top: 112px solid #040707;
    position: absolute;
    right: 10em;
    transform: rotate(100deg);
    top: 4em;
  }
}

@media (min-width: 1400px) {
  .request-access-triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 55px solid transparent;
    border-right: 55px solid transparent;
    border-top: 112px solid #040707;
    position: absolute;
    right: 19em;
    transform: rotate(100deg);
    top: 0;
  }
}

h2.request-access-content {
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  margin: 0;
  margin-bottom: 24px;
  @media screen and (max-width: 768px) {
    font-size: 45px;
    line-height: 125%;
    letter-spacing: -0.5px;
    margin-bottom: 40px;
  }
}

p.request-access-content {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  margin: 0;
  margin-bottom: 24px;
  color: #637381;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 40px;
  }
}

button.request-access-content {
  width: 222px;
  height: 54px;
  background: #ed1f24;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  margin-top: 1em;

  &:hover {
    opacity: 70%;
  }
}
