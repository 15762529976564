/* FONTS */
$main-font: "Inter",  sans-serif;
$secondary-font: 'Roboto',  sans-serif;

/* COLORS */
$color-black-emphasis: #000 !default;
$color-primary: #B21F24 !default;
$color-primary-accent: #47246B !default;
$color-high-emphasis: #FFFFFF !default;
$color-pink: #F8C9CA !default;

$color-black-interactive: rgba(0, 0, 0, 0.54) !default;

$assets_url: '../../public/' !default;