/*ai Priori Diff */

.aip-diff {
    padding: 80px 0;
    padding-bottom: 0;
    width: 100%;
    position: relative;
    background: #FAFAFA;
    overflow: hidden;
    padding-bottom: 49px;
    &.dark-bg {
        background-color: rgba(0, 0, 0, 0.04);
    }
    @media (max-width: 768px) {
        background: white;
        padding: 56px 0;
    }
    
}

.aip-diff-title {
    font-family: Inter;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.5px;
    margin: 0;
    margin-bottom: 80px;
    @media (max-width: 768px) {
        font-size: 45px;
        margin-bottom: 40px;
        text-align: left;
    }

}

.aip-diff-card {
    background: #f8c9ca;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 1em;
    padding: 40px;

    &.gray-variant {
        background: rgba(0, 0, 0, 0.10);
    }

    .heading {
        display: flex;
        flex-flow: row;
        gap: 24px;
    }

    h3 {
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 29px;
        text-align: left;
        @media (max-width: 768px) {
            margin: 0;
            font-size: 22px;
            line-height: 150%;
        }
    }

    p {
        position: relative;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(65, 65, 65, 0.95);
        text-align: left;
        margin: 0;
        margin-top: 29px;
        @media (max-width: 768px) {
            margin-top: 24px;
        }
    }
}

.api-diff-card-icon {
    position: relative;
    width: 70px;
    height: 70px;

    flex: 0 0 70px;
    background: white;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
    border-radius: 15px;

    > img {
        position: relative;
        width: 61%;
        top: 16px;
    }
}

.aip-diff-card-grp {
    padding: 1em;
    position: relative;
    max-width: 800px;
    margin: auto;
    padding: 0;
    @media (max-width: 768px) {
        padding: 0 24px;
    }
}

@media (max-width: 1000px) {
    .aip-diff-card-grp {
        position: relative;
        padding: 1em;
        left: 0;
    }
}

.aip-diff-circle {
    position: absolute;
    width: 398.03px;
    height: 398.03px;
    border-radius: 50%;
    background: #ed1f24;
    right: -13em;
}

@media (min-width: 1400px) {
    .aip-diff-black-triangle {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 55px solid transparent;
        border-right: 55px solid transparent;
        border-top: 112px solid #040707;
        position: absolute;
        right: 26em;
        transform: rotate(125deg);
        top: 28em;
    }
}

@media (min-width: 1200px) {
    .aip-diff-red-triangle {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 55px solid transparent;
        border-right: 55px solid transparent;
        border-top: 112px solid #ed1f24;
        position: absolute;
        right: 10em;
        transform: rotate(45deg);
        top: 45em;
    }
}
