.closing-gap {
    position: relative;
    width: 100%;
    height: 575px;
    background: #FAFAFA;
    overflow: hidden;
}
@media (max-width: 768px) {
    .closing-gap {
        height: initial;
    }
}

.closing-gap-content>h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    color: #2E2E2E;
}


@media (max-width: 1150px) {
    .closing-gap-card-grp {
        position: relative;
        top: 50px;
        display: flex;
    }
}

@media (min-width: 1151px) {
    .closing-gap-card-grp {
        position: relative;
        top: 50px;
        display: -webkit-inline-box;
    }
}

@media (max-width: 768px) {
    .closing-gap-card-grp {
        flex-flow: column;
        align-items: center;
        gap: 32px;
        top: 0;
        padding-bottom: 56px;
    }
}

.closing-gap-card {
    position: relative;
    width: 370px;
    height: 256px;
    background: #FFFFFF;
    box-shadow: 0px 60px 120px -20px #EBEFFD;
    padding-top: 1px;
    padding-bottom: 1px;
}

.closing-gap-card-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #000000;
    text-align: left;
    padding: 1em;
}

.closing-gap-card-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #32435C;
}

.closing-gap-card-org {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #969696;
}

.title-block {
    position: absolute;
    bottom: 0;
    left: 30%;
}

.title-block-lg {
    position: absolute;
    bottom: 0;
    left: 20%;
}

.qoute {
    position: absolute;

}