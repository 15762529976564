/*Call to Action Styles*/
/*1080*/

div.info-overload {
    padding-top: 6em;
    margin-top: 3em;
    height: 1229px;
    background: #FAFAFA;
    position: relative;
    overflow: hidden;
    display: flex;
}


@media (min-width: 1080px) {
    div.info-overload {
        padding-top: 6em;
        height: 908px;
        background: #FAFAFA;
        position: relative;
        overflow: hidden;
    }
}

@media (min-width: 1400px) {
    div.info-overload {
        padding-top: 6em;
        height: 858px;
        background: #FAFAFA;
        position: relative;
        overflow: hidden;
    }
}

@media (min-width: 1600px) {
    div.info-overload {
        padding-top: 6em;
        height: 783px;
        background: #FAFAFA;
        position: relative;
        overflow: hidden;
    }
}

div.info-overload-content {
    width: 50%;
    position: absolute;
    right: 3em;
}

div.info-overload-content>h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 46px;
    color: #212B36;
    text-align: center;
}

div.info-overload-content>p {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #637381;
    text-align: left;
    padding-bottom: 1.5em
}

div.info-overload-content>ul>li {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #637381;
    text-align: left;
    padding-bottom: 1.5em
}

button.info-overload-content {
    width: 180px;
    height: 54px;
    background: #ED1F24;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #FFFFFF;

}

div.info-overload-content>button:hover {
    opacity: 70%;
}

div.info-overload-img-grp {
    position: relative;
    left: 1em;
    display: inline;
    width: 40%;
    top: 1em;
}

img.tackle-img1 {
    width: 35%;
    position: relative;
    left: 1em;
    top: 20%;
    z-index: 1;
    border-radius: 2%;
}


img.tackle-img3 {
    width: 45%;
    position: relative;
    left: 11em;
    top: -4em;
    z-index: 0;
    border-radius: 2%;
}

@media (max-width: 700px) {
    img.tackle-img3 {
        display: none;
    }

    img.tackle-img1 {
        display: none;
    }

    div.info-overload-content {
        width: 80%;
    }

    div.info-overload {
        height: 1012px;
    }
}

@media (max-width: 1143px) {
    img.tackle-img2 {
        display: none;
    }

    img.tackle-img3 {
        width: 45%;
        position: relative;
        left: 1em;
        top: 6em;
        z-index: 0;
    }

}

@media (min-width: 1143px) {
    img.tackle-img2 {
        width: 48%;
        position: relative;
        left: -7em;
        top: 1em;
        z-index: 1;
        border-radius: 2%;
    }
}