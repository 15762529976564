@import "variables";

.page-hero-section {
    background: rgba(0, 0, 0, 0.87);
    padding: 80px 24px;
    color: white;
    @media screen and (max-width: 768px) {
        padding: 48px 32px;
        position: relative;
        overflow: hidden;
        &:before {
            content: "";
            background: #ed2024;
            position: absolute;
            right: -349px;
            top: -13px;
            width: 362px;
            height: 362px;
            border-radius: 362px;
          }
          &:after {
            content: "";
            background-image: url("#{$assets_url}/img/triangle.svg");
            position: absolute;
            left: -50px;
            top: 124px;
            width: 71.535px;
            height: 83.951px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
          }
    }
    .heading-container {
        display: flex;
        max-width: 1200px;
        margin: auto;
        @media screen and (max-width: 768px) {
            max-width: initial;
            justify-content: center;
        }
        h1 {
            font-family: Inter;
            font-size: 44px;
            font-style: normal;
            font-weight: 600;
            line-height: 55px;

            max-width: 480px;
            text-align: left;
            margin: 0;

            @media screen and (max-width: 768px) {
                font-size: 56px;
                font-weight: 400;
                text-align: center;
            }
        }
    }
    &.center {
        .heading-container {
            justify-content: center;
            h1 {
                text-align: center;
            }
        }
    }
}
