.learning-hub {
    position: relative;
    width: 100%;
    height: 470px;
    background: #FAFAFA;
    overflow: hidden;
}



.learning-hub-circle {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: #ED1F24;
    left: -14em;
    bottom: 2em;
}


.learning-hub-content-container {
    overflow: hidden;
    position: absolute;
    width: 80%;
    height: 450px;
    left: 10%;
    top: 5%;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

h2.learning-hub-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 46px;
    color: #212B36;
}

p.learning-hub-content-sub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #212b36;
    text-align: center;
    padding-left: 5em;
    padding-right: 5em;
}

@media (max-width: 1000px) {
    p.learning-hub-content {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #637381;
        text-align: left;
        padding-left: 5em;
        padding-right: 5em;
    }
}

@media (min-width: 1000px) {
    p.learning-hub-content {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #637381;
        text-align: left;
        padding-left: 10em;
        padding-right: 10em;
    }
}


@media (min-width: 1400px) {
    p.learning-hub-content {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #637381;
        text-align: left;
        padding-left: 20em;
        padding-right: 20em;
    }
}

button.learning-hub-content {
    width: 222px;
    height: 54px;
    background: #ED1F24;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    position: relative;
    margin-top: 2em;
}

button.learning-hub-content:hover {
    opacity: 70%;
}

@media (max-width: 768px) {
    .learning-hub {
        padding: 56px 32px;
        width: initial;
        height: initial;
    }
    .learning-hub-content-container {
        width: initial;
        left: initial;
        top: initial;
        height: initial;
        position: initial;
        padding: 32px;
    }
    h2.learning-hub-content {
        margin: 0;
    }
    p.learning-hub-content-sub {
        padding: 0;
    }
    p.learning-hub-content {
        padding: 0;
    }
}