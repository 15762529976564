.the-team {
  position: relative;
  background: #fafafa;
  overflow: hidden;
  padding: 88px 32px;
  @media screen and (max-width: 768px) {
    padding: 56px 32px;
  }
}

.the-team-circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #ed1f24;
  left: -14em;
  bottom: 2em;
}

.team-container {
  h2 {
    color: #212b36;

    text-align: center;
    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    margin: 0;

    margin-bottom: 56px;
  }
  .team-sliders {
    max-width: 1005px;
    margin: auto;
    margin-bottom: 56px;
    .team-slide {
      display: flex;
      gap: 40px;
      @media screen and (max-width: 992px) and (min-width: 769px) {
        gap: 32px;
      }
      @media screen and (max-width: 768px) {
        flex-flow: column;
      }

      .team-member-image-container {
        border-radius: 175px;
        background: #d9d9d9 url("../../public/img/Emily-Yu-2022-01.jpg")
          no-repeat center center;
        background-size: cover;
        width: 350px;
        height: 350px;
        overflow: hidden;
        background-position: left -3em center ;
        @media screen and (max-width: 992px) {
          width: 250px;
          height: 250px;
        }
        @media screen and (max-width: 768px) {
          margin: auto;
        }
      }
    }
    .team-member-description {
      text-align: left;
      p {
        color: #637381;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        margin: 0;
        margin-bottom: 24px;

        a {
          margin: initial;
          display: inline-block;
          padding: 0 5px;
          color: var(--Primary-500---Accent, #47246b);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px;
          text-decoration-line: underline;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
h2.the-team-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 46px;
  color: #212b36;
}

@media (max-width: 1000px) {
  p.the-team-content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #637381;
    text-align: left;
    padding-left: 5em;
    padding-right: 5em;
  }
}

@media (min-width: 1000px) {
  p.the-team-content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #637381;
    text-align: left;
    padding-left: 10em;
    padding-right: 10em;
  }
}

@media (min-width: 1400px) {
  p.the-team-content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #637381;
    text-align: left;
    padding-left: 20em;
    padding-right: 20em;
  }
}

button.the-team-content {
  width: 222px;
  height: 54px;
  background: #ed1f24;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  position: relative;
  margin-top: 2em;

  &:hover {
    opacity: 70%;
  }
}
