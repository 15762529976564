.benefits-for-data-partners {
  height: auto;
  padding-bottom: 50px;
  width: 100%;
  position: relative;
  background: #fafafa;
  overflow: hidden;
}

.benefits-for-data-partners-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #2e2e2e;
}

.benefits-for-data-partners-card {
  background: rgba(225 225 225);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 235px;
  height: 320px;
  flex: 0 0 235px;
  margin: 1em;
  padding: 15px;
  display: inline-block;
  position: relative;
  z-index: 1;
  /* Higher z-index to ensure it's above the background elements */
}

.benefits-for-data-partners-card>h3,
.benefits-for-data-partners-card>p {
  position: relative;
  left: 0;
  top: 0;
  margin: 10px 0;
  text-align: center;
}

.benefits-for-data-partners-card>p {
  color: rgba(65, 65, 65, 0.95);
}

.benefits-for-data-partners-card-icon {
  margin: 0 auto;
  width: 70px;
  height: 70px;
  background: white;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  display: block;
}

.benefits-for-data-partners-card-icon>img {
  position: relative;
  width: 61%;
  top: 16px;
}

.benefits-for-data-partners-card-grp {
  /* Existing Styles */
  padding: 1em;
  margin: 0 auto;
  max-width: 86em;
  position: relative;
  z-index: 1;
  /* Higher z-index to ensure it's above the background elements */

  /* Flex Styles */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  /* Optional (if you want to align child items in the center vertically) */
  align-items: center;
}

@media (max-width: 1000px) {
  .benefits-for-data-partners-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.benefits-for-data-partners-circle {
  position: absolute;
  width: 398.03px;
  height: 398.03px;
  border-radius: 50%;
  background: #ed1f24;
  right: -13em;
  position: absolute;
  z-index: 0;
}

@media (min-width: 1400px) {
  .benefits-for-data-partners-black-triangle {
    width: 0;
    height: 0;
    border-left: 55px solid transparent;
    border-right: 55px solid transparent;
    border-top: 112px solid #040707;
    position: absolute;
    right: 26em;
    transform: rotate(125deg);
    top: 28em;
    z-index: 0;
  }
}

@media (min-width: 1200px) {
  .benefits-for-data-partners-red-triangle {
    width: 0;
    height: 0;
    border-left: 55px solid transparent;
    border-right: 55px solid transparent;
    border-top: 112px solid #ed1f24;
    position: absolute;
    right: 102em;
    transform: rotate(45deg);
    top: 23em;
    z-index: 0;
  }
}

.background-shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

button.collab {
  width: 180px;
  height: 54px;
  background: #ed1f24;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

button.collab:hover {
  opacity: 70%;
}

div.bottom {
  padding-top: 2em;
  z-index: 1;
  position: relative;
}

.gray-link {
  color: lightgray !important;
  /* The !important ensures it overrides any other styles */
  text-decoration: none;
  /* This removes the underline */
}

.gray-link:hover {
  color: darkgray;
  /* Change color on hover to something slightly darker for interactivity */
}