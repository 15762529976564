/*Hero Section*/
@import "variables";

div.join-us {
  background-image: url("#{$assets_url}/img/aipBackgroundImg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: initial;
  padding: 40px 32px;
  width: initial;
  display: flex;
  justify-content: center;
}

@media (max-width: 1080px) {
  img.join-us {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

img.join-us {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

div.join-us-content {
  overflow: hidden;
  background: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
  width: 100%;
  position: initial;
  max-width: 800px;
  padding: 56px;
  padding-bottom: 48px;
  @media (max-width: 768px) {
    padding: 56px 32px;
    padding-bottom: 48px;
  }

  > h2 {
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px;
    color: #ffffff;
    position: relative;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 28px;
      font-style: normal;
      line-height: 150%;
    }
  }
}

p.join-us-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #ffffff;
  padding-right: 1em;
  max-width: 420px;
}

input.join-us-content {
  width: 306px;
  height: 35px;
  background: #fafafa;
}

label.join-us-content {
  position: relative;
  display: -webkit-inline-box;
  justify-self: right;
}

fieldset.join-us-content {
  position: relative;
  display: inline-grid;
  border: none;
  margin-bottom: 32px;
  padding: 0;
}

.join-us-content-button {
  margin-top: 1em;
  width: 207px;
  height: 52px;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(15, 19, 19, 0.95);
  position: relative;
  left: 10em;

  &:hover {
    opacity: 70%;
  }
}

form.join-us-content {
  display: inline-grid;
  width: 100%;
  max-width: 420px;
}
