/*Hero Section*/
div.login {
    width: 100%;
    height: 45em;
    overflow: hidden;
    position: relative;
}

@media (max-width: 1080px) {
    img.login {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

}

img.login {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

div.login-content {
    overflow: hidden;
    background-size: cover;
    position: absolute;
    width: 70%;
    height: 400px;
    left: 15%;
    top: 229px;
    background: rgba(15, 19, 19, 0.95);
    border-radius: 20px;
}

div.login-content>h2 {
    height: 58.46px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 55px;
    color: #FFFFFF;
    position: relative;
    left: 1em;
}

p.login-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #FFFFFF;
    padding-right: 1em;
}

input.login-content {
    width: 306px;
    height: 35px;
    background: #FAFAFA;
}

label.login-content {
    position: relative;
    display: -webkit-inline-box;
    justify-self: center;
}

fieldset.login-content {
    position: relative;
    display: inline-grid;
    border: none;
}

button.login-content {
    margin-top: 1em;
    width: 207px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba(15, 19, 19, 0.95);
    position: relative;
    left: 10em;
}

button.login-content:hover {
    opacity: 70%;
}

form.login-content {
    display: inline-grid;
}