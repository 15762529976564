/*Call to Action Styles*/
/*1080*/
section {
    &.call-to-action {
        padding: 120px 30px;
        background: #fafafa;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 768px) {
            padding: 56px 24px;
            padding-top: 0;
        }
    }

    .call-to-action-content {
        width: 100%;
        max-width: 1200px;
        margin: auto;
        display: flex;
        text-align: initial;
        gap: 72px;

        .text-container {
            width: 50%;
            flex: 0 0 auto;
            display: flex;
            flex-flow: column;
            gap: 32px;
            align-items: start;
            @media screen and (max-width: 768px) {
                width: 100%;
                gap: 40px;
                align-items: initial;
            }

            h2 {
                font-family: Inter;
                font-size: 60px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%; /* 75px */
                letter-spacing: -0.5px;
                margin: 0;
                @media screen and (max-width: 768px) {
                    font-size: 45px;
                }
            }

            p {
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                letter-spacing: 0.5px;
                margin: 0;
                padding: 0;
            }
            .MuiButton-root {
                @media screen and (max-width: 768px) {
                    width: 100%;
                    max-width: 350px;
                    margin: auto;
                }
            }
        }
        .image-container {
        }
        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
            box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
        }
    }
}

.show-mobile {
    @media screen and (min-width: 769px) {
        display: none;
    }
}
.show-desktop {
    @media screen and (max-width: 768px) {
        display: none;
    }
}
