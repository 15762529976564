/*Data Driven Insights Styles*/
/*1080*/

.data-driven-insights {
  display: flex;
  gap: 104px;
  padding: 80px 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.04);
  overflow: hidden;
  justify-content: center;
  @media (max-width: 1200px) {
    gap: 50px;
  }
  @media (max-width: 992px) {
    flex-flow: column;
    gap: 20px;
  }
  @media (max-width: 600px) {
    padding-top: 0;
    padding-bottom: 56px;
  }
  .image-container {
    width: 50%;
    padding: 42px 0;
    text-align: right;
    @media (max-width: 992px) {
      width: initial;
      text-align: center;
    }
    @media (max-width: 600px) {
      padding: 0;
    }
    width: initial;
    img {
      width: 100%;
      max-width: 654px;
      box-shadow: 0px 28px 16px -26px #000;
    }
  }
  .data-driven-insights-content {
    padding-right: 30px;
    @media (min-width: 1200px) {
      max-width: 500px;
    }
    @media (max-width: 1200px) {
      width: 60%;
    }
    @media (max-width: 992px) {
      margin: auto;
    }
    @media (max-width: 600px) {
      padding: 0 24px;
      width: initial;
    }
  }
}

.dd_img {
  border-radius: 20px;
}

@media (min-width: 1200px) {
  .data-driven-insights > img {
    padding-top: 2em;
    left: 0;
  }
}

.data-driven-insights-content > h2 {
  text-align: initial;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.5px;
  padding: 0;
  margin: 0;
  margin-bottom: 58px;
  @media (max-width: 1200px) {
    font-size: 50px;
  }
  @media (max-width: 600px) {
    font-size: 45px;
    margin-bottom: 40px;
  }
}

.data-driven-insights-content-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  padding-top: 25px;
  color: #040707;
  padding-left: 16px;
  text-align: left;
  @media (max-width: 600px) {
    padding-top: 0;
    display: flex;
    align-items: center;
  }
}

.data-driven-insights-red-circle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 78px;
  color: #b21f24;
  border: 2px solid #ed1f24;
  flex: 0 0 80px;
  @media (max-width: 600px) {
    width: 64px;
    height: 64px;
    flex: 0 0 64px;
    line-height: 64px;
    color: #b21f24;
  }
}

.data-driven-insights-content-bullet {
  display: -webkit-box;
  padding-top: 2em;
  display: flex;
  &:first-of-type {
    padding-top: 0;
  }
}

.data-driven-insights-content-bullet-3 {
    display: flex;
    flex-direction: flex-start; /* Align children vertically */
    /* Rest of your styles */
  }
  
  .data-driven-insights-content-bullet-3 p,
  .data-driven-insights-content-bu0let-3 li {
    font-family: "Inter", sans-serif; /* Ensure fallback fonts are present */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
  
  .data-driven-insights-content-bullet-3 p {
    color: #637381;
    font-family: "Roboto", sans-serif; /* Ensure fallback fonts are present */
    position: absolute;
    margin-top: 6.5em;
    margin-left: 7em;
  }

.data-driven-insights-content-bullet-2-text {
  text-align: left;
  padding-left: 100px;
  @media (max-width: 600px) {
    padding-left: 82px;
  }
  ul {
    list-style: none;
    p,
    li {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      margin-top: -8px;
      margin-bottom: -5px;
    }
  }
  p {
    color: #637381;
    font-family: Roboto;
  }
}
