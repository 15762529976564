/*Call to Action Styles*/
/*1080*/

section.changeChange {
    position: relative;
    padding: 0 24px;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
    .change-change-container {
        max-width: 1200px;
        margin: auto;
        display: flex;
        gap: 127px;
        padding-top: 45px;
        padding-bottom: 90px;
        @media screen and (max-width: 1200px) {
            gap: 56px;
        }
        @media screen and (max-width: 768px) {
            flex-flow: column-reverse;
            padding: 0;
            gap: initial;
        }
    }
    .changeChange-content {
        width: 50%;
        flex: 0 0 50%;
        text-align: left;
        @media screen and (max-width: 768px) {
            width: initial;
            flex: initial;
            padding: 32px;
            text-align: initial;
            display: inline-block;
        }
        h3 {
            color: rgba(0, 0, 0, 0.6);
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 36px;
            margin: 0;
            margin-bottom: 35px;
            @media screen and (max-width: 768px) {
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 0.5px;
                margin-bottom: 32px;
            }
        }

        p, li {
            color: rgba(0, 0, 0, 0.6);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15px;
            margin: 0;
            margin-bottom: 32px;
        }
        li {
            margin-bottom: -11px; /* Reduced spacing between list items */
        }
        p, ul {
            margin-bottom: 32px; /* Keeps bottom margin for paragraphs and the whole list */
        }
        .MuiButton-root  {
            @media screen and (max-width: 768px) {
                margin: auto;
                display: block;
            }
        }
    }
    .changeChange-image {
        img.changeChange {
            max-width: 100%;
            border-radius: 20px;
            box-shadow: 0px 28px 16px -26px #000;
            position: relative;
            right: inherit;
            top: inherit;
            width: 100%;
            height: inherit;
            margin-top: -181px;
            @media screen and (max-width: 768px) {
                border-radius: 0;
                margin-top: initial;
                box-shadow: none;
            }
        }
    }
}

button.changeChange-content {
    width: 180px;
    height: 54px;
    background: #ed1f24;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
}

div.changeChange-content > button:hover {
    opacity: 70%;
}

@media (min-width: 800px) {
    img.changeChange {
        height: 22em;
        right: -6em;
        top: 17em;
        position: absolute;
    }
}

@media (min-width: 1000px) {
    img.changeChange {
        height: 22em;
        right: 1%;
        top: 17em;
        position: absolute;
    }
}

@media (min-width: 1200px) {
    img.changeChange {
        right: 6%;
        height: 22em;
        position: absolute;
        top: 12em;
    }
}

@media (min-width: 1400px) {
    img.changeChange {
        right: 10%;
        position: absolute;
        top: 7em;
    }
}
