@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');


.App {
  text-align: center;
}

.imgborder {
  -webkit-box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
  -moz-box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
  box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
}

@media (min-width: 1903px) {
  div.maxWidth {
    width: 1903px;
    display: inline-block;
  }
}
.page-margin {
  margin-top: 150px;
}
@media (max-width: 992px) {
  .page-margin {
    margin-top: 69px;
  }
}